import React, { useState, useEffect, useContext } from "react";
import {
  Table,
  Button,
  Input,
  Modal,
  Select,
  Popconfirm,
  message,
  Form,
  Typography,
} from "antd";
import { get, patch, post } from "../../api-services/fetch";
import { SwapTableContext } from "../Contexts/SwapTableContext";
import axios from "axios";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import notification from "../../utility/notification";
import parse from "html-react-parser";
import moment from "moment";
// import ViewGovTable from './ViewGovTable';
// import Cookies from 'js-cookie';
import Festac from "../../assets/images/Festac-logo.jpg";
import EdsgLogo from "../../assets/images/edo-logo-250.png";
import { decodeToken } from "../../utility/auth";
// import EditScreen from './EditScreen';
// import {  } from 'antd';
import {
  CloseOutlined,
  SearchOutlined,
  PaperClipOutlined,
  DeleteTwoTone,
} from "@ant-design/icons";
import { AiOutlineCloseSquare } from "react-icons/ai";
import UploadButton from "../layouts/UploadButton";
import SearchDocument from "../layouts/SearchDocument";
// const BASE_API_URL = 'https://edogoverp.com/budget/api/v1';
// const BASE_API_URL = 'http://localhost:5000/api/v1';

// allMdaDrop: '/api/employee/mdas',

const EditScreen = (prop) => {
  const {
    handleImage,
    imageHolder,
    mFilesArray,
    handleMfilesArray,
    holdMfilesArray,
  } = useContext(SwapTableContext);

  const { TextArea } = Input;
  const { Option } = Select;
  const [preview, setPreview] = useState(false);
  // const [Approvers, setApprovers] = useState([])
  const [employee, setEmployee] = useState([]);
  const [subject, setSubject] = useState("");
  const [userId, setuserId] = useState([]);
  const [submits, setSubmit] = useState(false);
  const [saves, setSaves] = useState(false);
  const [show, setShow] = useState(false);
  const [read, setRead] = useState(true);
  const [costImplication, setCostImplication] = useState();
  const [to, setTo] = useState("");
  const [memoRequestsId, setmemoRequestsId] = useState("");
  const [newTextArea, setNewTextArea] = useState(prop?.contents?.description);
  const [isLoading, setIsLoading] = useState(false);

  const [pendingApprovals, setPendingApprovals] = useState([]);

  const [form] = Form.useForm();

  function numberWithCommas(x) {
    let y = x + "";
    if (y?.includes("N")) {
      y = x?.replace(/N/g, "");
    }

    if (y?.includes(",")) {
      return y
        ?.replace(/,/g, "")
        ?.toString()
        ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } else {
      return y?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  }

  useEffect(() => {
    // console.log(Cookies.get('signature'))
    // console.log(prop?.contents?.documents)

    // holdMfilesArray(prop?.contents?.documents)

    prop.preview == "saved" ? setShow(true) : setShow(false);
    // (prop.preview=='saved') ? setRead(false):setRead(true)
    // setUsername(`${fn} ${ln}`);

    console.log(prop.contents);
    console.log(prop.payload);
    let ui = localStorage.getItem("ui");
    ui = decodeToken("ui").ui;
    console.log(ui);
    setuserId(ui);
    // setmemoRequestsId(prop.contents?.approvers[0]?.memoRequestsId);
    setmemoRequestsId(prop.contents?.id);

    let filteredThrough = prop?.contents?.approvers?.filter(
      (e) => e.userId !== prop?.contents?.recipient.id
    );
    console.log(filteredThrough);
    let thru = filteredThrough?.map((e, idx) => ({
      key: e?.id,
      id: e?.id,
      sequence: e?.sequence,
      memoRequestsId: e?.memoRequestsId,
      userId: +e?.userId,
      actionTaken: e?.actionTaken,
      mdaId: e?.mdaId,
      createdAt: e?.createdAt,
      modifiedAt: "",
      createdBy: e?.createdBy,
      modifiedBy: "",
      isDelete: e?.isDelete,
      label: e?.name,
      value: e.userId,
    }));

    let to = {
      key: prop?.contents?.recipient?.id,
      id: prop?.contents?.recipient?.id,
      value: prop?.contents?.recipient?.id,
      name: prop?.contents?.recipient?.name,
    };

    if (prop.preview == "saving" || prop.preview == "submitting") {
      form.setFieldsValue({ from: prop?.previewValues?.from });
      form.setFieldsValue({ to: [to] });
      form.setFieldsValue({ subject: prop?.previewValues?.subject });
      form.setFieldsValue({ details: prop?.previewValues?.details });
      setNewTextArea(prop?.previewValues?.details);
      form.setFieldsValue({ thru: thru });
      // form.setFieldsValue({costImplication:prop?.previewValues?.costImplication})
      form.setFieldsValue({
        costImplication:
          prop?.contents?.costImplication == 0
            ? `N0.00`
            : `N${numberWithCommas(prop?.contents?.costImplication)}`,
      });
    } else {
      form.setFieldsValue({ from: prop?.contents?.createdBy });
      form.setFieldsValue({ to: to });
      form.setFieldsValue({ subject: prop?.contents?.title });
      setNewTextArea(prop?.contents?.description);
      form.setFieldsValue({ details: prop?.contents?.description });
      form.setFieldsValue({ thru: thru });
      form.setFieldsValue({
        costImplication:
          prop?.contents?.financialImplication == 0
            ? `N0.00`
            : `N${numberWithCommas(prop?.contents?.financialImplication)}`,
      });
      setCostImplication(prop?.contents?.financialImplication);
      // form.setFieldsValue({costImplication:prop?.contents?.financialImplication})
    }

    setSubject(prop?.contents?.title);
    getEmployeeList();

    console.log(prop.contents.documents);
    let p = prop.contents.documents.map((e, idx) => ({
      key: idx + 1,
      createdAt: e.createdAt,
      createdBy: e.createdBy,
      name: e.docName,
      path: e.docPath,
      id: e.id,
      isDelete: e.isDelete,
      memoRequestsId: e.memoRequestsId,
      modifiedAt: e.modifiedAt,
      modifiedBy: e.modifiedBy,
    }));
    holdMfilesArray(p);
  }, [
    prop?.contents,
    prop.preview,
    prop?.previewValues,
    prop.payloads,
    prop?.previewValues,
  ]);

  const handleTextAreaChange = (e, editor) => {
    const data = editor.getData();
    setNewTextArea(data);
  };
  const toPost = async (payload) => {
    const res = await post({
      endpoint: "/api/Memo/memo",
      body: payload,
      auth: true,
    });
    console.log(res);

    if (res && res.status === 200) {
      const { data } = res;
      if (data.code === 1) {
        // setEmployee(data.data)
        notification({
          title: data.status,
          message: data.message,
          type: "success",
        });
        setSaves(false);
        setSubmit(false);
        prop.handleCancel2();
      } else {
        notification({
          title: "Error Getting List of Employees",
          message: data.message,
          type: "danger",
        });
        setSaves(false);
        setSubmit(false);
      }
    } else {
      setSaves(false);
      setSubmit(false);

      notification({
        title: "Network",
        message: "Something has gone wrong. Please, try again.",
        type: "danger",
      });
    }
  };
  const toPostSave = async (payload, id) => {
    const res = await patch({
      endpoint: "/api/Memo/memo",
      body: payload,
      param: id,
      auth: true,
    });
    console.log(res);

    if (res && res.status === 200) {
      const { data } = res;
      if (data.code === 1) {
        // setEmployee(data.data)
        notification({
          title: data.status,
          message: data.message,
          type: "success",
        });
        setSaves(false);
        setSubmit(false);
        setIsLoading(false);
        prop.handleCancel2();
      } else {
        notification({
          title: "Error Getting List of Employees",
          message: data.message,
          type: "danger",
        });
        setSaves(false);
        setSubmit(false);
      }
    } else {
      setSaves(false);
      setSubmit(false);

      notification({
        title: "Network",
        message: "Something has gone wrong. Please, try again.",
        type: "danger",
      });
    }
  };

  const getEmployeeList = async () => {
    const res = await get({ endpoint: "employee", auth: true });
    console.log(res);

    if (res && res.status === 200) {
      const { data } = res;
      if (data.code === 1) {
        console.log(data.data);
        setEmployee(data.data);
      } else {
        notification({
          title: "Error Getting List of Employees",
          message: data.message,
          type: "danger",
        });
      }
    } else {
      notification({
        title: "Network",
        message: "Something has gone wrong. Please, try again.",
        type: "danger",
      });
    }
  };

  const onFinish = (values) => {
    setIsLoading(true);
    let costImp = values.costImplication?.replace(/,/g, "")?.replace(/N/g, "");
    console.log("Success:", values);
    console.log("Success:", costImp);

    if (isNaN(+costImp))
      notification({
        title: "Cost Implication Error",
        message: `${costImp} is not a valid number. Please check and input valid number in cost Implication field`,
        type: "danger",
      });
    //     console.log(userId)
    let approvers =
      values?.thru && values?.thru?.length !== 0
        ? values.thru.map((e, idx) => ({
            id: e?.id ? e?.id : 0,
            sequence: idx + 1,
            memoRequestsId: memoRequestsId,
            userId: +e.value,
            actionTaken: "string",
            mdaId: prop.contents.mda.id,
            createdAt: "2022-02-26T18:29:27.222Z",
            modifiedAt: "2022-02-26T18:29:27.222Z",
            createdBy: +userId,
            modifiedBy: +userId,
            isDelete: false,
          }))
        : [];

    let documents = mFilesArray.map((e) => ({
      // createdAt: e.createdAt,
      // createdBy: e.createdBy,
      name: e.name,
      path: e.path,
      id: +e.id,
      // isDelete: e.isDelete,
      // memoRequestsId:e.memoRequestsId,
      // modifiedAt: e.modifiedAt,
      // modifiedBy:e.modifiedBy
    }));

    let poster = {
      id: +prop?.contents.id,
      to: values.to.value,
      from: +userId,
      subject: values.subject,
      // details: values.details,
      details: newTextArea,
      costImplication: +costImp,
      status: 1,
      documents: documents,
      mdaId: prop.contents.mda.id,
      approvers: approvers,
    };
    console.log(prop?.contents?.id);
    console.log(poster);
    //   toPost(prop.payload)
    toPostSave(poster, prop?.contents?.id);

    // holdMfilesArray(p)
  };

  return (
    <div className="w-100">
      <div className="flex-between flex-align w-100 p-20">
        <div className="sidebar-header">
          <img src={EdsgLogo} alt="logo" className="brand" width="150" />
        </div>
        <div style={{ textAlign: "center" }}>
          <b>{prop?.contents?.mdaAddress?.name.toUpperCase()}</b>
          {/* <p className="no-margin">7th Floor, Block C, Secretariat Building, Sapele Road,</p> */}
          <p className="no-margin">{prop?.contents?.mdaAddress?.address}</p>
        </div>
        <div>
          {/* <p className='bold-text no-margin'>Internal Memo</p>  
  <p className='' style={{fontSize:"10px", opacity:"0.5"}}>August 12 2022</p>   */}
          <img src={Festac} width="40px" />
        </div>
      </div>

      <div className=" w-100">
        <div style={{ width: "100%" }} className=" w-60 m-r-10">
          <div
            style={{ fontSize: "", backgroundColor: "#D5FFD5" }}
            className="p-10"
          >
            <p>
              Memo Subject:{" "}
              <span className="bold-text">{subject?.toUpperCase()}</span>
            </p>
          </div>

          <Form
            form={form}
            onFinish={onFinish}
            labelCol={{ span: 3 }}
            // layout='vertical'
            className=" w-100 m-r-10"
            style={{ backgroundColor: "white", padding: "20px 20px 20px 0" }}
          >
            <Form.Item
              className="w-100"
              label="To"
              name="to"
              rules={[
                {
                  required: true,
                  message: "Please select who you are sending to!",
                },
              ]}
            >
              <Select
                onChange={(text, index) => {
                  setTo(index?.children);
                }}
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                filterSort={(optionA, optionB) =>
                  optionA.children
                    .toLowerCase()
                    .localeCompare(optionB.children.toLowerCase())
                }
                //   mode="multiple"
                labelInValue
                allowClear
                style={{ width: "100%", border: "" }}
                //   onChange={e => this.setState({ details: e.target.value })}
                placeholder="Please select"
                //   defaultValue={['a10', 'c12']}
                //   onChange={handleChange}
              >
                {Array.isArray(employee) &&
                  employee?.map((obj) => (
                    <Option value={obj?.id} key={obj?.id}>{`${obj?.firstName} ${
                      obj?.lastName
                    } ${obj?.role ? obj?.role : ""} ${obj?.mda}`}</Option>
                  ))}
                {/* {children} */}
              </Select>
            </Form.Item>

            <Form.Item className="w-100" label="From" name="from">
              <Input readOnly />
            </Form.Item>

            {prop?.contents?.approvers &&
              prop?.contents?.approvers?.length > 1 && (
                <Form.Item
                  className="w-100"
                  label="Through"
                  name="thru"
                  // rules={[
                  // {
                  // required: true,
                  // message: 'Please input Approvers!',
                  // },
                  // ]}
                >
                  <Select
                    // onChange = {(value)=>handleApprovers(value)}
                    showSearch
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    filterSort={(optionA, optionB) =>
                      optionA.children
                        .toLowerCase()
                        .localeCompare(optionB.children.toLowerCase())
                    }
                    mode="multiple"
                    labelInValue
                    allowClear
                    style={{ width: "100%", border: "" }}
                    //   onChange={e => this.setState({ details: e.target.value })}
                    placeholder="Please select"
                    //   defaultValue={['a10', 'c12']}
                    //   onChange={handleChange}
                  >
                    {Array.isArray(employee) &&
                      employee?.map((obj) => (
                        <Option value={obj.id} key={obj.id}>{`${
                          obj.firstName
                        } ${obj.lastName} ${obj.role ? obj.role : ""} ${
                          obj.mda
                        }`}</Option>
                      ))}
                    {/* {children} */}
                  </Select>
                </Form.Item>
              )}
            <Form.Item
              className="w-100"
              label="Subject"
              name="subject"
              rules={[
                {
                  required: true,
                  message: "Please input subject!",
                },
              ]}
            >
              <Input
                onChange={(e) => {
                  setSubject(e.target.value);
                }}
                style={{ width: "100%", border: "" }}
              />
            </Form.Item>
            <Form.Item
              className="w-100"
              label="Cost"
              name="costImplication"
              // rules={[
              // {
              // required: true,
              // message: 'Please input Cost!',
              // },
              // ]}
            >
              <Input
                onChange={(e) => {
                  form.setFieldsValue({
                    costImplication: `N${numberWithCommas(e.target?.value)}`,
                  });
                  setCostImplication(e.target.value);
                }}
                style={{ width: "100%", border: "" }}
              />
            </Form.Item>

            <Form.Item
              className="w-100"
              label="Details"
              name="details"
              rules={[
                {
                  required: true,
                  message: "Please input details!",
                },
              ]}
            >
              {/* <TextArea  
            autoSize={{ minRows: 2, maxRows: 8 }}
            className='w-100'
        style={{ width: "100%", border: "" }} /> */}
              {/* {newTextArea!=''&&alert(newTextArea)} */}
              <CKEditor
                editor={ClassicEditor}
                data={newTextArea}
                onChange={handleTextAreaChange}
                config={{
                  // removePlugins: [ 'Paragraph' ],
                  removePlugins: ["Link", "CKFinder", "Image", "Media"],
                  // toolbar: [ 'bold', 'italic', '|', 'undo', 'redo' ]
                  // toolbar: [ 'heading', '|', 'bold', 'italic', 'link', 'TableSelection', 'bulletedList', 'numberedList', 'blockQuote', 'undo', 'redo'],
                  // toolbar: [ 'heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'tables', 'numberedList', 'blockQuote' ],
                  heading: {
                    options: [
                      {
                        model: "paragraph",
                        title: "Paragraph",
                        class: "ck-heading_paragraph",
                      },
                      {
                        model: "heading1",
                        view: "h1",
                        title: "Heading 1",
                        class: "ck-heading_heading1",
                      },
                      {
                        model: "heading2",
                        view: "h2",
                        title: "Heading 2",
                        class: "ck-heading_heading2",
                      },
                    ],
                  },
                }}
              />

              {/* <div className="m-t-5">{parse(prop?.payload?.details?.replaceAll("<td>", "<td className='border'>")||prop?.contents?.description?.replaceAll("<td>", "<td className='border'>"))}</div> */}
            </Form.Item>

            <Form.Item className="flex-align flex-between p-20">
              <div className="">
                {prop.signature && (
                  <div>
                    <img
                      style={{ width: "160px", height: "70px" }}
                      src={prop?.signature}
                    />
                    <p className="bold-text m-l-20">
                      {prop?.contents?.createdBy}
                    </p>
                  </div>
                )}
                <p>Upload Attachment</p>
                <div style={{ width: "40%" }}>
                  <UploadButton
                    p="Upload Documents"
                    style={{ marginBottom: "3px" }}
                  />
                  <div style={{ marginTop: "5px" }}>
                    <SearchDocument />
                  </div>
                </div>
              </div>

              {
                <Button
                  loading={isLoading}
                  block
                  className="m-r-10"
                  htmlType="submit"
                  onClick={() => {
                    setSaves(true);
                  }}
                >
                  Save Draft
                </Button>
              }
              {/* {<Button block  className='m-r-10' htmlType="submit" onClick = {()=>{setSubmit(true)}}>Submit Request</Button>} */}
              {/* <Button className='m-r-10'  onClick={showPreview}>Preview Draft</Button> */}
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default EditScreen;
