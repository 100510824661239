/* eslint-disable linebreak-style */
/* eslint-disable react/jsx-props-no-spreading */
import React, {
  PureComponent,
  useContext,
  useState,
  useEffect,
  useRef,
} from "react";

import notification from "../../utility/notification";
import { SwapTableContext } from "../Contexts/SwapTableContext";
import { logout } from "../../utility/auth";

const SessionHOC = (ProtectedComponent) =>
  class AutoLogout extends PureComponent {
    static contextType = SwapTableContext;

    constructor(props) {
      super(props);
      this.state = {
        warningTime: 1000 * 60 * 5,
        signoutTime: 1000 * 60 * 7,
      };
    }

    componentDidMount() {
      const events = [
        "load",
        "mousedown",
        "mousemove",
        "click",
        "scroll",
        "keypress",
      ];

      events.forEach((event) => {
        window.addEventListener(event, this.resetTimeout);
      });

      this.setTimeout();
    }

    componentWillUnmount() {
      const events = [
        "load",
        "mousedown",
        "mousemove",
        "click",
        "scroll",
        "keypress",
      ];

      events.forEach((event) => {
        window.removeEventListener(event, this.resetTimeout);
      });
      clearTimeout(this.warnTimeout);
      clearTimeout(this.logoutTimeout);
    }

    componentDidUpdate(prevProps, prevState) {
      const {
        reloadDev,
        handleReloadDev,
        mFilesArray,
        handleMfilesArray,
        handleImage,
        imageHolder,
        handleApproversArray,
        approversArray,
      } = this.context;

      // Typical usage (don't forget to compare props):
      // if (this.props.select !== prevProps.select) {

      //     console.log((this.props.select))
      // }
      if (reloadDev !== prevState.reloadDev) {
        console.log("looking");
        this.resetTimeout();
        clearTimeout(this.warnTimeout);
        clearTimeout(this.logoutTimeout);
      }
    }

    clearTimeoutFunc = () => {
      if (this.warnTimeout) {
        clearTimeout(this.warnTimeout);
      }
      if (this.logoutTimeout) {
        clearTimeout(this.logoutTimeout);
      }
    };

    setTimeout = () => {
      const { warningTime, signoutTime } = this.state;
      this.warnTimeout = setTimeout(this.warn, warningTime);
      this.logoutTimeout = setTimeout(() => logout(), signoutTime);
    };

    resetTimeout = () => {
      this.clearTimeoutFunc();
      this.setTimeout();
    };

    warn = () => {
      notification({
        title: "SESSION TIMEOUT WARNING",
        message:
          "Due to inactivity on the portal, you will be logged out automatically in the next 5 minutes. To avoid being logged out, do something on the portal.",
        type: "warning",
      });
    };

    render() {
      return (
        <>
          <ProtectedComponent {...this.props} />
        </>
      );
    }
  };

export default SessionHOC;
