import React, { useState, useEffect, useContext } from "react";
import {
  Table,
  Button,
  Input,
  Modal,
  Select,
  Popconfirm,
  Form,
  Typography,
} from "antd";
import { get, patch, post } from "../../api-services/fetch";
import axios from "axios";
import parse from "html-react-parser";
import notification from "../../utility/notification";
import { SwapTableContext } from "../Contexts/SwapTableContext";
import moment from "moment";
import { PDFExport } from "@progress/kendo-react-pdf";
// import html2canvas from 'html2canvas';
// import jsPDF from 'jspdf';
// import ViewGovTable from './ViewGovTable';
// import Cookies from 'js-cookie';
import EdsgLogo from "../../assets/images/edo-logo-250.png";
import Festac from "../../assets/images/Festac-logo.jpg";
import { BrowserRouter, useLocation, Link } from "react-router-dom";
// import Pdf from "react-to-pdf";
import { decodeToken } from "../../utility/auth";
// import AssignedMemos from './AssignedMemos';
import { CloseOutlined, SearchOutlined } from "@ant-design/icons";
// import ApproverTable from '../layouts/ApproverTable';
import ApproverTable from "../layouts/ApproverTable";
import { AiOutlineCloseSquare } from "react-icons/ai";
import { FcDocument } from "react-icons/fc";
import UploadButton from "../layouts/UploadButton";
import SearchDocument from "../layouts/SearchDocument";
// import MemoPDF from './MemoPdf';
const BASE_API_URL = "https://edogoverp.com/budget/api/v1";
// const BASE_API_URL = 'http://localhost:5000/api/v1';

// allMdaDrop: '/api/employee/mdas',

const ref = React.createRef();

const AssignedMemos = (prop) => {
  const pdfExportComponent = React.useRef(null);

  const {
    handleImage,
    imageHolder,
    mFilesArray,
    handleMfilesArray,
    holdMfilesArray,
  } = useContext(SwapTableContext);
  const location = useLocation();
  const currentPath = location.pathname;

  const { TextArea } = Input;
  const { Option } = Select;
  const [preview, setPreview] = useState(false);
  // const [Approvers, setApprovers] = useState([])
  const [employee, setEmployee] = useState([]);

  const [subject, setSubject] = useState("");
  const [userId, setuserId] = useState([]);
  const [assigneeId, setAssigneeId] = useState([]);
  const [assigneeName, setAssigneeName] = useState([]);
  const [submits, setSubmit] = useState(false);
  const [saves, setSaves] = useState(false);
  const [show, setShow] = useState(false);
  const [approversComments, setApproversComments] = useState("");
  const [hideApprovers, setHideApprovers] = useState(false);
  const [hideAction, setHideAction] = useState(true);
  const [costImplication, setCostImplication] = useState();
  const [remove, setRemove] = useState(false);
  const [hideForSubmit, setHideForSubmit] = useState(false);
  const [pendingApprovals, setPendingApprovals] = useState([]);
  const [pdfName, setPdfName] = useState("");
  const [assignersComments, setAssignersComments] = useState("");
  const [hideAssign, setHideAssign] = useState(true);
  const [trackAssign, setTrackAssign] = useState(true);
  const [getRepliez, setGetRepliez] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [theMemoRequestId, setTheMemoRequestId] = useState(0);

  const [trackMemosYouAssigned, setTrackMemosYouAssigned] = useState([]);

  const [form] = Form.useForm();

  useEffect(() => {
    console.log(prop?.contents);

    console.log(prop?.contents?.approvers);
    setRemove(false);

    setPdfName(subject);
    let filteredThrough = (
      prop?.contents?.approvers || prop?.contents?.memoRequestApprovers
    )?.filter(
      (e) =>
        e.userId !==
        (prop?.contents?.recipient?.id || prop?.contents?.recipient)
    );
    let thru = filteredThrough?.map((e, idx) => ({
      key: e?.id,
      id: e?.id,
      sequence: e?.sequence,
      memoRequestsId: e?.memoRequestsId,
      userId: +e?.userId,
      actionTaken: e?.actionTaken,
      mdaId: e?.mdaId,
      createdAt: e?.createdAt,
      modifiedAt: e?.modifiedAt,
      createdBy: e?.createdBy,
      modifiedBy: e?.modifiedBy,
      isDelete: e?.isDelete,
      label: e?.name,
      value: e.userId,
    }));

    // console.log(Cookies.get('signature'))
    // console.log(Cookies.get('signature'))

    // (prop.preview=='saved') ? setRead(false):setRead(true)
    // setUsername(`${fn} ${ln}`);

    console.log(prop?.contents);
    console.log(prop?.payload);
    let ui = localStorage.getItem("ui");
    ui = decodeToken("ui").ui;
    console.log(ui);
    setuserId(ui);

    // alert(prop?.contents?.memoRequest?.details)

    form.setFieldsValue({ from: prop?.contents?.memoRequest?.fromName });
    form.setFieldsValue({
      to:
        prop?.contents?.memoRequest?.toName ||
        prop?.contents?.recipient?.memoRequest?.name,
    });
    form.setFieldsValue({
      subject:
        prop?.contents?.memoRequest?.subject ||
        prop?.contents?.memoRequest?.title,
    });
    form.setFieldsValue({ details: prop?.contents?.memoRequest?.details });
    form.setFieldsValue({ thru: thru });
    //form.setFieldsValue({costImplication:prop?.contents?.financialImplication||prop?.contents?.costImplication})
    form.setFieldsValue({
      costImplication:
        prop?.contents?.memoRequest?.financialImplication == 0 ||
        prop?.contents?.memoRequest?.costImplication == 0
          ? `N0.00`
          : `N${numberWithCommas(
              prop?.contents?.memoRequest?.financialImplication ||
                prop?.contents?.memoRequest?.costImplication
            )}`,
    });
    // setCostImplication(prop?.contents?.costImplication)

    setAssignersComments("");
    setApproversComments("");
    setAssigneeName("");

    setSubject(prop?.contents?.memoRequest?.title);
    getEmployeeList();
    getAssignerEnquiry();

    holdMfilesArray([]);
  }, [prop?.contents?.memoRequest?.id]);

  const toPpp = () => {
    prop.handleCancel2();
    setAssignersComments("");
    setApproversComments("");
  };

  const toPostAssigned = async (status) => {
    setIsLoading(true);

    if (!assignersComments) {
      notification({
        title: "Error Assigning Request",
        message: "Please enter comments before re-assigning this query",
        type: "danger",
      });
      return;
    }
    let approverDocuments =
      mFilesArray?.length !== 0
        ? mFilesArray.map((e) => ({
            id: 0,
            memoRequestsId: prop?.contents?.id,
            docName: e.name,
            docPath: e.path,
            createdAt: "2022-03-08T08:35:46.953Z",
            modifiedAt: "2022-03-08T08:35:46.953Z",
            createdBy: userId,
            modifiedBy: userId,
            isDelete: false,
          }))
        : [];

    let assignees =
      status == "assigned"
        ? [
            {
              assigneeId,
              assignerId: userId,
              memoRequestId: prop?.contents?.memoRequest?.id,
              comment: assignersComments,
            },
          ]
        : [];

    let payload = {
      requestId: prop?.contents?.memoRequest?.id,
      comment: assignersComments,
      approverDocuments: approverDocuments,
      action: status,
      assignees: assignees,
    };

    console.log(payload);

    const res = await patch({
      endpoint: "/api/Memo/approve",
      body: payload,
      auth: true,
    });
    console.log(res);

    if (res && res.status === 200) {
      const { data } = res;
      if (data.code === 1) {
        // setEmployee(data.data)
        notification({
          title: data.status,
          message: data.message,
          type: "success",
        });

        prop.handleCancel2();
        setAssignersComments("");
        setApproversComments("");
        setIsLoading(false);

        setAssigneeName("");
        payload = {
          requestId: 0,
          comment: "",
          approverDocuments: [],
          action: "",
          assignees: [],
        };
      } else {
        notification({
          title: "Error Approving Request",
          message: data.message,
          type: "danger",
        });
      }
    } else {
      notification({
        title: "Network",
        message: "Something has gone wrong. Please, try again.",
        type: "danger",
      });
    }
  };

  const toPost = async (status) => {
    setIsLoading(true);

    let payload = {
      enquiryId: +prop?.contents?.enquiry?.id,
      comment: approversComments,
    };

    console.log(payload);

    const res = await post({
      endpoint: "/api/Memo/reply",
      body: payload,
      auth: true,
    });
    console.log(res);

    if (res && res.status === 200) {
      const { data } = res;
      if (data.code === 1) {
        // setEmployee(data.data)
        notification({
          title: data.status,
          message: data.message,
          type: "success",
        });
        setAssignersComments("");
        setApproversComments("");
        prop.handleCancel2();
        setIsLoading(false);
      } else {
        notification({
          title: "Error Approving Request",
          message: data.message,
          type: "danger",
        });
      }
    } else {
      notification({
        title: "Network",
        message: "Something has gone wrong. Please, try again.",
        type: "danger",
      });
    }
  };

  function numberWithCommas(x) {
    return x?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  console.log(prop?.contents?.memoRequest?.queryRequestDocuments);

  console.log(currentPath);
  const getEmployeeList = async () => {
    const res = await get({ endpoint: "employee", auth: true });
    console.log(res);

    if (res && res.status === 200) {
      const { data } = res;
      if (data.code === 1) {
        console.log(data.data);
        setEmployee(data.data);
      } else {
        notification({
          title: "Error Getting List of Employees",
          message: data.message,
          type: "danger",
        });
      }
    } else {
      notification({
        title: "Network",
        message: "Something has gone wrong. Please, try again.",
        type: "danger",
      });
    }
  };

  const getAssignerEnquiry = async () => {
    const res = await get({
      endpoint: "/api/Memo/assigner-enquiry",
      auth: true,
    });
    console.log(res);

    if (res && res.status === 200) {
      const { data } = res;
      if (data.code === 1) {
        console.log(data.data);
        let pp = data.data.filter(
          (e) => e.memoRequest.id === prop?.contents?.memoRequest?.id
        );
        setTrackMemosYouAssigned(pp);
      } else {
        notification({
          title: "Error Getting List of Employees",
          message: data.message,
          type: "danger",
        });
      }
    } else {
      notification({
        title: "Network",
        message: "Something has gone wrong. Please, try again.",
        type: "danger",
      });
    }
  };

  const onReset = () => {
    form.resetFields();
  };

  // const options = {
  //   orientation: 'potrait',
  //   unit: 'in',
  //   format: [10, 13]
  // };

  // const printDocument = () => {

  //   setRemove(true);
  //   setHideForSubmit(true);

  //   setTimeout(() => {
  //     console.log('clicked');
  //     const input = document.getElementById('divToPrint');

  //     html2canvas(input, { allowTaint: true, useCORS: true }).then((canvas) => {
  //       const imgData = canvas.toDataURL('image/jpeg', 1.0);
  //       const pdf = new jsPDF('p', 'px', 'a4');
  //       const pageWidth = pdf.internal.pageSize.getWidth();
  //       const pageHeight = pdf.internal.pageSize.getHeight();
  //       var imgHeight = canvas.height * pageWidth / canvas.width;
  //       var heightLeft = pageHeight;

  //       const widthRatio = pageWidth / canvas.width;
  //       const heightRatio = pageHeight / canvas.height;
  //       const ratio = widthRatio > heightRatio ? heightRatio : widthRatio;

  //       const canvasWidth = canvas.width * ratio;
  //       const canvasHeight = canvas.height * ratio;

  //       const marginX = (pageWidth - canvasWidth) / 2;
  //       const marginY = (pageHeight - canvasHeight) / 2;
  //       pdf.addImage(imgData, 'JPEG', marginX, marginY, canvasWidth, canvasHeight);
  //       var position = 0;
  //       heightLeft -= pageHeight;

  //       while (heightLeft >= 0) {
  //         position = heightLeft - pageHeight;
  //         pdf.addPage();
  //         pdf.addImage(imgData, 'PNG', 0, position, pageWidth, pageHeight);
  //         heightLeft -= pageHeight;
  //       }
  //       pdf.save(`${subject} Memo.pdf`);
  //     }, 1000);

  //     setTimeout(() => {
  //       setRemove(false);
  //       setHideForSubmit(false);
  //     }, 2000);

  //   });
  // }

  const onFinish = (values) => {
    // if(submits){console.log(prop.payload)
    // prop.payload.status=8}
    // else if (saves){console.log(prop.payload)
    // prop.payload.status=9}
    // else
    // return
    //     console.log('Success:', values);
    //     console.log(userId)
    //   let approvers = values.thru.map((e,idx)=>(
    //     {
    //         id: 0,
    //         sequence: idx+1,
    //         memoRequestsId: 0,
    //         userId: +e.value,
    //         actionTaken: "string",
    //         mdaId: 0,
    //         createdAt: "2022-02-26T18:29:27.222Z",
    //         modifiedAt: "2022-02-26T18:29:27.222Z",
    //         createdBy: +userId,
    //         modifiedBy: +userId,
    //         isDelete: false
    //       }
    //     ))
    //     let poster =  {
    //         to: values.to,
    //         from: +userId,
    //         subject: values.subject,
    //         details: values.details,
    //         status: submits?1:saves?2:'',
    //         documents: [
    //           {
    //             id: 0,
    //             name: 'string',
    //             path: 'string'
    //           }
    //         ],
    //         mdaId: 11,
    //         approvers:approvers
    //       }
    //   toPost(prop.payload)
    //   saves && toPostSave(poster)
  };

  const toRemove = () => {
    setRemove(true);
  };

  return (
    <div className="w-100">
      <div
        style={{ marginLLeft: "auto", justifyContent: "flex-start" }}
        className="mb5 w-100 flex  m-t-20"
      >
        <button
          style={{ maxWidth: "650px", marginLLeft: "auto" }}
          className="btn btn "
          onClick={() => {
            toRemove();

            if (pdfExportComponent.current) {
              setTimeout(() => {
                pdfExportComponent.current.save();

                setRemove(false);
                // setHideForSubmit(false);
              }, 50);
            }
          }}
        >
          Generate PDF
        </button>
      </div>
      {/* {(!remove && currentPath == '/schedule-manager/all-memos') && <Pdf options={options} targetRef={ref} filename="code-example.pdf">
        {({ toPdf }) => <button onClick={() => {
          toPdf()
          toRemove()

        }}>Generate Pdf</button>}
      </Pdf>} */}

      <PDFExport
        paperSize="auto"
        margin="2mm"
        fileName={`${pdfName}.pdf`}
        ref={pdfExportComponent}
      >
        <div
          id="divToPrint"
          style={{
            width: remove && "21cm",
            height: remove && "29.7cm",
            margin: remove && "30mm 45mm 30mm 45mm",
          }}
        >
          <div className="flex-between flex-align w-100 p-20">
            <div className="sidebar-header">
              <img src={EdsgLogo} alt="logo" className="brand" width="150" />
            </div>
            <div style={{ textAlign: "center" }}>
              <b>{prop?.contents?.memoRequest?.mdaName?.toUpperCase()}</b>
              {/* <p className="no-margin">7th Floor, Block C, Secretariat Building, Sapele Road,</p> */}
              <p className="no-margin">
                {prop?.contents?.memoRequest?.mdaAddress}
              </p>
            </div>
            <div>
              {/* <p className='bold-text no-margin'>Internal Memo</p>  
            <p className='' style={{fontSize:"10px", opacity:"0.5"}}>August 12 2022</p>   */}
              <img src={Festac} width="40px" />
            </div>
          </div>

          <div className=" w-100">
            <div style={{ width: "100%" }} className=" w-60 m-r-10">
              <div
                style={{ fontSize: "", backgroundColor: "#D5FFD5" }}
                className="p-10 flex-between"
              >
                <p>
                  Memo Subject:{" "}
                  <span className="bold-text">
                    {(
                      prop?.contents?.memoRequest?.subject ||
                      prop?.contents?.memoRequest?.title
                    )?.toUpperCase()}
                  </span>
                </p>
                <p>
                  Memo Initiation Date:{" "}
                  <span className="bold-text">
                    {prop?.contents?.memoRequest?.createdAt
                      ? moment(prop?.contents?.memoRequest?.createdAt).format(
                          "LL"
                        )
                      : ""}
                  </span>
                </p>
              </div>

              <Form
                form={form}
                onFinish={onFinish}
                labelCol={{ span: 3 }}
                // layout='vertical'
                className=" w-100 m-r-10"
                style={{
                  backgroundColor: "white",
                  padding: "20px 20px 20px 0",
                }}
              >
                <Form.Item className="w-100" label="To" name="to">
                  <Input bordered={show} readOnly={!show} />
                </Form.Item>

                <Form.Item className="w-100" label="From" name="from">
                  <Input bordered={show} readOnly={!show} />
                </Form.Item>

                {prop?.contents?.approvers &&
                  prop?.contents?.memoRequest?.approvers?.length > 1 && (
                    <Form.Item
                      className="w-100"
                      label="Through"
                      name="thru"
                      rules={[
                        {
                          required: true,
                          message: "Please input Approvers!",
                        },
                      ]}
                    >
                      <Select
                        disabled
                        readOnly
                        bordered={false}
                        // onChange = {(value)=>handleApprovers(value)}
                        showSearch
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        filterSort={(optionA, optionB) =>
                          optionA.children
                            .toLowerCase()
                            .localeCompare(optionB.children.toLowerCase())
                        }
                        mode="multiple"
                        labelInValue
                        allowClear
                        style={{ width: "100%", border: "" }}
                        //   onChange={e => this.setState({ details: e.target.value })}
                        placeholder="Please select"
                        //   defaultValue={['a10', 'c12']}
                        //   onChange={handleChange}
                      >
                        {Array.isArray(employee) &&
                          employee?.map((obj) => (
                            <Option value={obj.id} key={obj.id}>{`${
                              obj.firstName
                            } ${obj.lastName} ${obj.role ? obj.role : ""} ${
                              obj.mda
                            }`}</Option>
                          ))}
                        {/* {children} */}
                      </Select>
                    </Form.Item>
                  )}

                <Form.Item
                  className="w-100"
                  label="Subject"
                  name="subject"
                  rules={[
                    {
                      required: true,
                      message: "Please input Approvers!",
                    },
                  ]}
                >
                  <Input
                    bordered={show}
                    readOnly={!show}
                    onChange={(e) => {
                      setSubject(e.target.value);
                    }}
                    style={{ width: "100%", border: "" }}
                  />
                </Form.Item>

                <Form.Item
                  className="w-100"
                  label="Cost"
                  name="costImplication"
                  rules={[
                    {
                      required: true,
                      message: "Please input Cost!",
                    },
                  ]}
                >
                  <Input
                    bordered={show}
                    readOnly={!show}
                    onChange={(e) => {
                      setCostImplication(e.target.value);
                    }}
                    style={{ width: "100%", border: "" }}
                  />
                </Form.Item>

                <Form.Item
                  className="w-100"
                  label="Details"
                  name="details"
                  rules={[
                    {
                      required: true,
                      message: "Please input Details!",
                    },
                  ]}
                >
                  <p className="m-t-5">
                    {/* {parse(prop?.contents?.memoRequest?.details?.replaceAll("<td>", "<td className='border'>") || prop?.contents?.description.replaceAll("<td>", "<td className='border'>"))} */}
                    {parse(
                      prop?.contents?.memoRequest?.details?.replaceAll(
                        "<td>",
                        "<td className='border'>"
                      )
                    )}
                    {/* {(prop?.contents?.details?.replaceAll("<td>", "<td className='border header-color'>") || prop?.contents?.description.replaceAll("<td>", "<td className='border header-color'>"))} */}
                  </p>
                </Form.Item>

                {prop.preview == "saved" ||
                prop.preview == "preview" ||
                prop.preview == "saving" ||
                prop.preview == "submitting" ? (
                  <Form.Item className="flex-align flex-between p-20">
                    <div className="flex-between">
                      <p>Upload Attachment</p>

                      {prop.signature && (
                        <div>
                          <img
                            style={{ width: "160px", height: "70px" }}
                            src={prop?.signature}
                          />
                          <p className="bold-text m-l-20">Signature</p>
                        </div>
                      )}
                    </div>

                    {(prop.preview == "saving" || prop.preview == "saved") && (
                      <Button
                        loading={isLoading}
                        block
                        className="m-r-10"
                        htmlType="submit"
                        onClick={() => {
                          setSaves(true);
                        }}
                      >
                        Save Draft
                      </Button>
                    )}
                    {(prop.preview == "submitting" ||
                      prop.preview == "saved") && (
                      <Button
                        loading={isLoading}
                        block
                        className="m-r-10"
                        htmlType="submit"
                        onClick={() => {
                          setSubmit(true);
                        }}
                      >
                        Submit Request
                      </Button>
                    )}
                    {/* <Button className='m-r-10'  onClick={showPreview}>Preview Draft</Button> */}
                  </Form.Item>
                ) : (
                  ""
                )}

                {/* <img
                                    width='100%'
                                    height='100%'
                                    src={`data:image/jpeg;base64, ${src[idx]}`}
                                    alt='signature'
                                  /> */}

                {prop?.contents?.signature && (
                  <div>
                    <img
                      style={{ width: "160px", height: "70px" }}
                      src={`data:image/jpeg;base64,${prop?.contents?.signature64}`}
                    />
                    <p className="bold-text m-l-20">
                      {" "}
                      {prop?.contents?.createdBy}
                    </p>
                  </div>
                )}

                {!remove && (
                  <div className="flex-between">
                    {(prop?.contents?.memoRequest?.queryRequestDocuments
                      ?.length !== 0 ||
                      prop?.contents?.memoRequest?.documents?.length !== 0) && (
                      <div>
                        <div className="bold-text" style={{ fontSize: "10px" }}>
                          Attached Documents
                        </div>
                        {
                          (prop?.contents?.memoRequest?.queryRequestDocuments
                            ?.length !== 0 ||
                            prop?.contents?.memoRequest?.documents?.length !==
                              0) &&
                            (
                              prop?.contents?.memoRequest
                                ?.queryRequestDocuments ||
                              prop?.contents?.memoRequest?.documents
                            )?.map((e) => (
                              //  <div className='flex-between p-5 border mFiles-hover m-r-10' style={{width:"",}}>
                              //  <a target='blank' download={e?.docName} href={e.docPath} >{e?.docName}</a>
                              //  </div>
                              <div className="flex-align">
                                <FcDocument style={{ fontSize: "40px" }} />
                                <a
                                  href={e.docPath}
                                  target="_blank"
                                  download={e?.docName}
                                >
                                  {e?.docName}
                                </a>
                              </div>

                              //       <div className="flex-align " style={{marginLeft:"3px"}}>
                              //           {/* <div className="pointer enlarge" onClick={()=>{del(x.id)}}> */}
                              //           {/* <DeleteTwoTone style={{fontSize:"22px"}}/> */}
                              //           {/* <FcDownload style={{fontSize:"16px"}}/> */}
                              //           {/* </div> */}

                              //          { console.log(x?.filePath?.slice(8))}
                              //            {/* <DeleteTwoTone style={{fontSize:"16px"}}/>  */}
                              //           <a style={{marginRight:"3px", display:"flex", alignItems:"center", padding:"5px 2px"}} className="pointer enlarge"  href={x.filePath}target="_blank" download={x.name}><FcDownload style={{fontSize:"24px"}}/></a>
                              //           <div className="pointer enlarge" onClick={()=>{del(x.id)}}>
                              //       <DeleteTwoTone style={{fontSize:"22px",marginLeft:"3px"}}/>

                              // </div>
                            ))
                          // <p className='bold-text' style={{fontSize:"10px"}}>No Attached Document</p>
                        }

                        {
                          prop?.contents?.memoRequest?.approverDocuments !==
                            0 &&
                            prop?.contents?.memoRequest?.approverDocuments?.map(
                              (e) => (
                                //  <div className='flex-between p-5 border mFiles-hover m-r-10' style={{width:"",}}>
                                //  <a target='blank' download={e?.docName} href={e.docPath} >{e?.docName}</a>
                                //  </div>
                                <div className="flex-align">
                                  <FcDocument style={{ fontSize: "40px" }} />
                                  <a href={e.docPath} target="_blank">
                                    {e?.docName}
                                  </a>
                                </div>
                              )
                            )
                          // <p className='bold-text' style={{fontSize:"10px"}}>No Attached Document</p>
                        }
                      </div>
                    )}
                  </div>
                )}
              </Form>

              {/* {(currentPath == '/schedule-manager/review-memos' && hideAction && prop.contents.status !== 'approved') && <div className='flex-between bold-text'>
              <div className='w-100 m-b-10' onClick={() => setHideAction(!hideAction)}>
                <p className='pointer p-5' style={{ width: "50%", margin: "auto", textAlign: "center", backgroundColor: "#008CBA", color: "white" }}>
                  Action
                </p>
              </div>

            </div>} */}

              {hideAction && prop?.contents?.status !== "approved" && (
                <div className="flex-between bold-text">
                  <div
                    className="w-100 m-b-10"
                    onClick={() => setHideAction(!hideAction)}
                  >
                    <p
                      className="pointer p-5"
                      style={{
                        width: "50%",
                        margin: "auto",
                        textAlign: "center",
                        backgroundColor: "#008CBA",
                        color: "white",
                      }}
                    >
                      Reply
                    </p>
                  </div>
                </div>
              )}

              {!hideAction && prop?.contents.status !== "approved" && (
                <div className="  bold-text w-100">
                  <div
                    className="flex-between p-10 m-b-10"
                    style={{ backgroundColor: "#D5FFD5" }}
                  >
                    <p className="no-margin">Reply</p>

                    <p
                      onClick={() => setHideAction(!hideAction)}
                      className="pointer p-5 no-margin"
                      style={{
                        backgroundColor: "#008CBA",
                        color: "white",
                        width: "30%",
                        textAlign: "center",
                      }}
                    >
                      Collapse
                    </p>
                  </div>
                  <div style={{ padding: "0 20px" }}>
                    <p>Assigner</p>

                    <div className="flex">
                      <img
                        className="m-r-10"
                        style={{
                          width: "50px",
                          height: "50px",
                          borderRadius: "50%",
                        }}
                        src={`data:image/jpeg;base64,${prop?.contents?.enquiry?.profilePicture}`}
                      />
                      <div>
                        <p style={{ color: "blue", margin: "0px" }}>
                          {prop?.contents?.enquiry?.assigner}
                        </p>
                        <p style={{ color: "", margin: "0px" }}>
                          {prop?.contents?.enquiry?.comments}
                        </p>
                      </div>
                    </div>
                    {prop?.contents?.replies &&
                      prop?.contents?.replies?.length !== 0 && (
                        <div
                          className="w-100"
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <div>
                            {prop?.contents?.replies &&
                              prop?.contents?.replies?.map((e) => (
                                <div>
                                  {/* <p>My Reply</p> */}

                                  <div className="flex p-5 m-t-5">
                                    <img
                                      className="m-r-10 "
                                      style={{
                                        width: "40px",
                                        height: "40px",
                                        borderRadius: "50%",
                                      }}
                                      src={e?.profilePicture}
                                    />
                                    <div>
                                      <p
                                        style={{ color: "blue", margin: "0px" }}
                                      >
                                        {e?.name}
                                      </p>
                                      <p
                                        style={{
                                          margin: "0px",
                                          fontSize: "11px",
                                        }}
                                      >
                                        {e?.comment}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              ))}
                          </div>
                        </div>
                      )}

                    {prop?.contents?.replies &&
                      prop?.contents?.replies?.length == 0 && (
                        <div>
                          <p className="bold-text m-t-10">
                            {" "}
                            Reply Assigner ({prop?.contents?.enquiry?.assigner})
                          </p>
                          <TextArea
                            value={approversComments}
                            onChange={(e) => {
                              setApproversComments(e?.target?.value);
                            }}
                            autoSize={{ minRows: 3 }}
                            className="w-100"
                            style={{ width: "100%", border: "" }}
                          />

                          <div className="" style={{ width: "100%" }}>
                            {/* <div className="flex-between m-t-10">
                {hideForSubmit ?
                    <></>
                    :
                    <div style={{ width: "25%" }}>
                      <div className='bold-text' style={{ fontSize: "12px" }}>Attach Additional Documents</div>
                      <UploadButton p='Upload Documents' style={{ marginBottom: "3px" }} />
                      <div style={{ marginTop: "5px" }}><SearchDocument /></div>
                    </div>}
                
                </div> */}
                            <div className="flex-between m-t-10">
                              {/* <Button className='bold' style={{ backgroundColor: "blue", color: "white", fontWeight:'bold'  }} htmlType='submit' onClick={() => { toPost('assigned') }}>Assign to { prop?.contents?.memoRequest?.fromName }</Button> */}
                              <Button
                                loading={isLoading}
                                className="bold"
                                style={{
                                  backgroundColor: "green",
                                  color: "white",
                                  fontWeight: "bold",
                                }}
                                htmlType="submit"
                                onClick={() => {
                                  toPost("assigned");
                                }}
                              >
                                Reply to {prop?.contents?.enquiry?.assigner}
                              </Button>
                            </div>
                          </div>
                        </div>
                      )}
                  </div>
                </div>
              )}
              {hideAssign && (
                <div className="flex-between bold-text m-t-20">
                  <div
                    className="w-100 m-b-10"
                    onClick={() => {
                      setHideAssign(!hideAssign);
                    }}
                  >
                    <p
                      className="pointer p-5"
                      style={{
                        width: "50%",
                        margin: "auto",
                        textAlign: "center",
                        backgroundColor: "#008CBA",
                        color: "white",
                      }}
                    >
                      {prop?.contents?.replies &&
                      prop?.contents?.replies?.length !== 0
                        ? "Re-assign This Memo"
                        : "Re-assign This Memo"}
                    </p>
                  </div>
                </div>
              )}

              {!hideAssign && (
                <div className=" m-t-20 bold-text w-100">
                  <div
                    className="flex-between p-10 m-b-10"
                    style={{ backgroundColor: "#D5FFD5" }}
                  >
                    <p className="no-margin">
                      {prop?.contents?.replies &&
                      prop?.contents?.replies?.length !== 0
                        ? "Assign / Replied Memos"
                        : "Re-assign Memo"}
                    </p>

                    <p
                      onClick={() => setHideAssign(!hideAssign)}
                      className="pointer p-5 no-margin"
                      style={{
                        backgroundColor: "#008CBA",
                        color: "white",
                        width: "30%",
                        textAlign: "center",
                      }}
                    >
                      Collapse
                    </p>
                  </div>
                  <div style={{ width: "85%", margin: "auto" }}>
                    <Select
                      bordered
                      onChange={(value, index) => {
                        // alert(index.name)
                        setAssigneeName(index.name);
                        setAssigneeId(value);
                      }}
                      showSearch
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      filterSort={(optionA, optionB) =>
                        optionA.children
                          .toLowerCase()
                          .localeCompare(optionB.children.toLowerCase())
                      }
                      // mode="multiple"
                      // labelInValue
                      allowClear
                      className="m-t-20"
                      style={{ width: "100%", border: "" }}
                      //   onChange={e => this.setState({ details: e.target.value })}
                      placeholder={
                        prop?.contents?.replies &&
                        prop?.contents?.replies?.length !== 0
                          ? "Select additional assignee"
                          : "Select assignee"
                      }
                      //   defaultValue={['a10', 'c12']}
                      //   onChange={handleChange}
                    >
                      {Array.isArray(employee) &&
                        employee?.map((obj) => (
                          <Option
                            name={`${obj.firstName} ${obj.lastName}`}
                            value={obj.id}
                            key={obj.id}
                          >{`${obj.firstName} ${obj.lastName} ${
                            obj.role ? obj.role : ""
                          } ${obj.mda}`}</Option>
                        ))}
                      {/* {children} */}
                    </Select>

                    <p className="bold-text m-t-5">Message</p>
                    <TextArea
                      value={assignersComments}
                      onChange={(e) => {
                        setAssignersComments(e?.target?.value);
                      }}
                      autoSize={{ minRows: 3 }}
                      // value = {assignersComments}
                      className="w-100"
                      style={{ width: "100%", border: "" }}
                    />

                    {/* <div className='flex-between border p-5'>
                       <p style={{width:"50%"}}>Name</p>
                       <p style={{width:"20%"}}>Request Status</p>
                       <p style={{width:"30%", textAlign:"right"}}>Signature</p>
                   </div>
               {   prop?.contents?.memoRequestApprovers.map((e)=>(
                   <div className='flex-between p-5'>
                       <p style={{width:"50%"}}>{e?.name}</p>
                       <p style={{width:"20%"}}>{e?.approvalStatus}</p>
                       <p style={{width:"30%"}}>{e?.signature}</p>
                   </div>
               ))
  
               } */}

                    {/* <div className='m-b-10' style={{ width: "100%", marginLeft:'auto' }}> */}
                    <div
                      className="m-t-10 flex"
                      style={{ width: "100%", justifyContent: "flex-end" }}
                    >
                      {/* <Button style={{ backgroundColor: "green", color: "white", }} htmlType='submit' onClick={() => { toPost('approved') }}>Approve</Button> */}
                      {/* <Button style={{ backgroundColor: "red", color: "white" }} htmlType='submit' onClick={() => { toPost('declined') }}>Decline</Button> */}
                      <Button
                        loading={isLoading}
                        style={{ backgroundColor: "#1C811C", color: "white" }}
                        htmlType="submit"
                        onClick={() => {
                          toPostAssigned("assigned");
                          // toPpp()
                        }}
                      >
                        Assign to {assigneeName}
                      </Button>
                    </div>
                    {/* </div> */}
                  </div>
                </div>
              )}

              {trackAssign && (
                <div className="flex-between bold-text m-t-20">
                  <div
                    className="w-100 m-b-10"
                    onClick={() => {
                      setTrackAssign(!trackAssign);
                    }}
                  >
                    <p
                      className="pointer p-5"
                      style={{
                        width: "50%",
                        margin: "auto",
                        textAlign: "center",
                        backgroundColor: "#008CBA",
                        color: "white",
                      }}
                    >
                      {"Track Memos You Re-assigned "}
                    </p>
                  </div>
                </div>
              )}

              {!trackAssign && (
                <div className=" m-t-20 bold-text w-100">
                  <div
                    className="flex-between p-10 m-b-10"
                    style={{ backgroundColor: "#D5FFD5" }}
                  >
                    <p className="no-margin">
                      {trackMemosYouAssigned?.replies &&
                      trackMemosYouAssigned?.replies?.length !== 0
                        ? "Track Memos You Re-assigned "
                        : "Track Memos You Re-assigned "}
                    </p>

                    <p
                      onClick={() => setTrackAssign(!trackAssign)}
                      className="pointer p-5 no-margin"
                      style={{
                        backgroundColor: "#008CBA",
                        color: "white",
                        width: "30%",
                        textAlign: "center",
                      }}
                    >
                      Collapse
                    </p>
                  </div>

                  {trackMemosYouAssigned.map((e) => (
                    <div>
                      <div className="flex" style={{ width: "48%" }}>
                        <img
                          className="m-r-10"
                          style={{
                            width: "50px",
                            height: "50px",
                            borderRadius: "50%",
                          }}
                          src={`data:image/jpeg;base64,${prop?.contents?.enquiry?.profilePicture}`}
                        />
                        <div>
                          <p style={{ color: "blue", margin: "0px" }}>
                            {e?.enquiry?.assigner}
                          </p>
                          <p
                            style={{
                              color: "",
                              margin: "0px",
                              fontSize: "11px",
                            }}
                          >
                            {e?.enquiry?.comments}
                          </p>
                        </div>
                      </div>

                      {e?.replies && e?.replies?.length !== 0 ? (
                        <div>
                          {e?.replies &&
                            e?.replies?.map((e) => (
                              <div
                                style={{
                                  width: "100%",
                                  display: "flex",
                                  justifyContent: "flex-end",
                                  marginTop: "-20px",
                                }}
                              >
                                <div
                                  className="flex w-100 border p-5 m-t-5"
                                  style={{ width: "48%" }}
                                >
                                  {/* <p>Reply From {e?.name}</p> */}

                                  <img
                                    className="m-r-10 "
                                    style={{
                                      width: "40px",
                                      height: "40px",
                                      borderRadius: "50%",
                                    }}
                                    src={e?.profilePicture}
                                  />
                                  <div>
                                    <p style={{ color: "blue", margin: "0px" }}>
                                      {e?.name}
                                    </p>
                                    <p
                                      style={{
                                        margin: "0px",
                                        fontSize: "11px",
                                      }}
                                    >
                                      {e?.comment}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            ))}
                        </div>
                      ) : (
                        <p>No reply yet</p>
                      )}
                    </div>
                  ))}

                  <div style={{ width: "85%", margin: "auto" }}>
                    {trackMemosYouAssigned?.replies &&
                      trackMemosYouAssigned?.replies?.length !== 0 && (
                        <div>
                          <p>Replies From Assignees</p>
                          {prop?.contents?.replies &&
                            prop?.contents?.replies?.map((e) => (
                              <div className="flex border p-5 m-t-5">
                                <img
                                  className="m-r-10 "
                                  style={{
                                    width: "40px",
                                    height: "40px",
                                    borderRadius: "50%",
                                  }}
                                  src={e?.profilePicture}
                                />
                                <div>
                                  <p style={{ color: "blue", margin: "0px" }}>
                                    {e?.name}
                                  </p>
                                  <p
                                    style={{ margin: "0px", fontSize: "11px" }}
                                  >
                                    {e?.comment}
                                  </p>
                                </div>
                              </div>
                            ))}
                        </div>
                      )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </PDFExport>
    </div>
  );
};

export default AssignedMemos;
