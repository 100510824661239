import React, { useState, useContext, useEffect } from "react";
import {
  Table,
  Tag,
  Modal,
  Tooltip,
  Popconfirm,
  Button,
  Spin,
  Dropdown,
  Badge,
  Input,
  Pagination,
} from "antd";
import { get, patch, post } from "../../api-services/fetch";
import { newget, newpatch, newpost, newdel } from "../../api-services/newfetch";

// import { Pagination } from 'antd';
import axios from "axios";
import { CloseOutlined, SearchOutlined } from "@ant-design/icons";
import { BsFillCircleFill, BsFillTrashFill } from "react-icons/bs";
import { TiPencil } from "react-icons/ti";
import { AiFillCaretDown } from "react-icons/ai";
// import {ModalContext} from './Contexts/ModalContext'
// import { SwapTableContext } from './Contexts/SwapTableContext'
// import IsLoading from '../assets/IsLoading';

// import notification from '../config/notification';
import notification from "../../utility/notification";
import moment from "moment";

import { BrowserRouter, useLocation, Link } from "react-router-dom";
import ApproveDeclineMemos from "./ApproveDeclineMemos";
import AssignedMemoz from "./AssignedMemos";

const AllMemos = (prop) => {
  const location = useLocation();
  const currentPath = location.pathname;

  const [isPending, setIsPending] = useState(true);
  const [isDeclined, setIsDeclined] = useState(false);
  const [isApproved, setIsApproved] = useState(false);

  const [pagTotal, setPagTotal] = useState(false);

  const [queryFilter, setqueryFilter] = useState(null);

  const [requestCount, setRequestCount] = useState(0);
  const [isEdit5FVisible, setIsEdit5FVisible] = useState(false);
  const [isEdit5FVisible3, setIsEdit5FVisible3] = useState(false);
  const [draftStatus, setDraftStatus] = useState(0);
  const [requests, setRequests] = useState([{}]);
  const [isSpin, setIsSpin] = useState(true);
  const [holdPageNumber, setholdPageNumber] = useState(1);

  let [paginationLength, setPaginationLength] = useState(10);

  let [valuez, setvaluez] = useState("");
  let [buttonName, setButtonName] = useState("");
  let [typedValue, setTypedValue] = useState("");
  let [sucess, setSucess] = useState("");
  let [content, setContent] = useState();
  let [replies, setReplies] = useState();
  let [pendingMemoLength, setpendingMemoLength] = useState(0);
  let [declinedMemosLength, setdeclinedMemosLength] = useState(0);
  let [approvedMemoLength, setapprovedMemoLength] = useState(0);
  let [pendingMemos, setPendingMemos] = useState([]);
  let [savedMemos, setSavedMemos] = useState([]);
  let [declinedMemos, setDeclinedMemos] = useState([]);
  let [approvedMemos, setApprovedMemos] = useState([]);
  let [assignedMemos, setAssignedMemos] = useState([]);
  let [actionedMemos, setActionedMemos] = useState([]);
  let [data1, setData1] = useState([]);
  let [data2, setData2] = useState([]);
  let [refresh, setRefresh] = useState(0);
  let [toAssigned, setToAssigned] = useState(false);
  // let [modulename, setModulename] = useState("")

  const [pendingApprovals, setPendingApprovals] = useState([]);

  console.log(currentPath);
  // let [loading, setLoading] = useState('')

  useEffect(() => {
    //   console.log(prop.modulename)
    getPendingMemo(holdPageNumber, 10, queryFilter);
    // getSavedMemo()
    getApprovedMemo(holdPageNumber, 10, queryFilter);
    // getDeclinedMemo(holdPageNumber,10,queryFilter)
    // getActionedMemo()
    // toPostPend(1,10)
    getAssignedMemo();

    // mapper()
  }, [refresh]);

  const varyPaginationLengthPending = () => {
    setPaginationLength(pendingMemoLength);
  };
  const varyPaginationLengthApproved = () => {
    setPaginationLength(approvedMemoLength);
  };
  const varyPaginationLengthDeclined = () => {
    setPaginationLength(declinedMemosLength);
  };
  useEffect(() => {
    //   console.log(prop.modulename)
    //   getPendingApprovals()
    mapper();
  }, [pendingApprovals]);

  const showModal = () => {};
  const handleCancel2 = () => {
    showPreview(!preview);
    // setPropz('')
    setRefresh(refresh + 1);
    // onReset()
    // setSubject('')
  };
  const getSavedMemo = async () => {
    let status = 1;
    const res = await get({
      endpoint: `/api/Memo/memos`,
      pQuery: { status: status },
      auth: true,
    });
    console.log(res);

    if (res && res.status === 200) {
      const { data } = res;
      if (data.code === 1) {
        console.log(data.data);
        setSavedMemos(data.data);
        // setMapPending(data.data)
        //   reducedPending(data.data)
        //   setShortPending(!shortPending)
      } else {
        notification({
          title: "Error Getting List of Employees",
          message: data.message,
          type: "danger",
        });
      }
    } else {
      notification({
        title: "Network",
        message: "Something has gone wrong. Please, try again.",
        type: "danger",
      });
    }
  };

  const toPostPend = async (pageNumber, pageSize) => {
    // setIsloading(true)

    let status = 2;
    let payload = {
      pageNumber,
      pageSize,
    };

    // axios
    // .post('app.com/api/login', data)
    // .then(response => response.json())
    // .then(response => {
    //    console.log(response.headers.get("x-auth-token"));
    // })
    // .catch(error => {
    //    console.log(error)
    // });

    const res = await post({
      endpoint: `/api/Memo/memos?status=${status}`,
      body: payload,
      auth: true,
    });
    console.log(res);
    // console.log(res.headers['x-pagination']);
    // console.log(Object.keys(res.headers))

    if (res && res.status === 200) {
      const { data } = res;
      if (data.code === 1) {
        // setEmployee(data.data)
        setPendingMemos(data.data);

        // notification({
        //     title:data.status,
        //     message: data.message,
        //     type: "success",
        //   });
        // setIsloading(false)

        // setSaves(false)
        // setSubmit(false)

        // prop.handleCancel2()
      } else {
        // notification({
        //   title: "Error Getting List of Employees",
        //   message: data.message,
        //   type: "danger",
        // });
        // setSaves(false)
        // setSubmit(false)
      }
    } else {
      // setSaves(false)
      // setSubmit(false)
      // notification({
      //   title: "Network",
      //   message: "Something has gone wrong. Please, try again.",
      //   type: "danger",
      // });
    }
  };

  const searchQuery = (e) => {
    setqueryFilter(e);

    console.log(pendingMemos[0]?.queryRequest?.subject);
    console.log(e);

    let pp = isPending
      ? pendingMemos.filter((obj) =>
          obj?.queryRequest?.subject?.toLowerCase()?.includes(e.toLowerCase())
        )
      : approvedMemos.filter((obj) =>
          obj?.queryRequest?.subject?.toLowerCase()?.includes(e.toLowerCase())
        );
    setPendingApprovals(pp);

    // if(queryFilter)
  };

  const getPendingMemo = async (pageNumber, pageSize, queryFilter) => {
    let status = 2;

    // let payload = {
    //   pageNumber, pageSize, queryFilter
    // }

    //  const status =
    //     [statuz]

    // const res = await get({ endpoint: `/api/Memo/memos`,pQuery:{status,pageNumber,pageSize},  auth: true, });
    //  const res = await post({ endpoint:`/api/Memo/memos?status=${status}`, body: payload,auth: true });
    const res = await newget({
      endpoint: `/api/Query/pending-replies`,
      //  body: payload,
      auth: true,
    });
    console.log(res);
    // console.log(res.headers['x-pagination']['TotalCount']);
    // console.log(JSON.parse(res.headers['x-pagination']));
    // console.log(res.headers['x-pagination']);
    // console.log(JSON.parse(res.headers['x-pagination'].'TotalCount');
    // setpendingMemoLength(JSON?.parse(res?.headers['x-pagination'])?.TotalCount)
    // setPaginationLength(JSON?.parse(res?.headers['x-pagination'])?.TotalCount)

    if (res && res.status === 200) {
      const { data } = res;
      if (data.code === 1) {
        console.log(data.data);
        setPendingMemos(data.data);
        isPending && setPendingApprovals(data.data);
        setIsSpin(false);

        // setMapPending(data.data)
        //   reducedPending(data.data)
        //   setShortPending(!shortPending)
      } else {
        notification({
          title: "Error Getting List of Employees",
          message: data.message,
          type: "danger",
        });
      }
    } else {
      notification({
        title: "Network",
        message: "Something has gone wrong. Please, try again.",
        type: "danger",
      });
    }
  };

  const getDeclinedMemo = async (pageNumber, pageSize, queryFilter) => {
    let status = 4;
    let payload = {
      pageNumber,
      pageSize,
      queryFilter,
    };

    //  const status =
    //     [statuz]

    // const res = await get({ endpoint: `/api/Memo/memos`,pQuery:{status,pageNumber,pageSize},  auth: true, });
    const res = await post({
      endpoint: `/api/Memo/memos?status=${status}`,
      body: payload,
      auth: true,
    });
    console.log(res);
    // setdeclinedMemosLength(JSON.parse(res.headers['x-pagination']).TotalCount)
    // console.log(res.headers['x-pagination']);
    // const res = await get({ endpoint: `/api/Memo/memos`,pQuery:{status:status}, auth: true });
    console.log(res);

    if (res && res.status === 200) {
      const { data } = res;
      if (data.code === 1) {
        console.log(data.data);
        setDeclinedMemos(data.data);
        isDeclined && setPendingApprovals(data.data);
        setIsSpin(false);

        // setMapPending(data.data)
        //   reducedPending(data.data)
        //   setShortPending(!shortPending)
      } else {
        notification({
          title: "Error Getting List of Employees",
          message: data.message,
          type: "danger",
        });
      }
    } else {
      notification({
        title: "Network",
        message: "Something has gone wrong. Please, try again.",
        type: "danger",
      });
    }
  };

  const getActionedMemo = async () => {
    let status = 4;
    const res = await get({ endpoint: `/api/Memo/actioned-memos`, auth: true });
    console.log(res);

    if (res && res.status === 200) {
      const { data } = res;
      if (data.code === 1) {
        console.log(data.data);
        setActionedMemos(data.data);
        // setMapPending(data.data)
        //   reducedPending(data.data)
        //   setShortPending(!shortPending)
      } else {
        notification({
          title: "Error Getting List of Employees",
          message: data.message,
          type: "danger",
        });
      }
    } else {
      notification({
        title: "Network",
        message: "Something has gone wrong. Please, try again.",
        type: "danger",
      });
    }
  };

  const getAssignedMemo = async () => {
    let status = 4;
    const res = await get({ endpoint: `/api/Memo/assigned-memos`, auth: true });
    console.log(res);

    if (res && res.status === 200) {
      const { data } = res;
      if (data.code === 1) {
        console.log(data.data);
        setAssignedMemos(data.data);
        // setMapPending(data.data)
        //   reducedPending(data.data)
        //   setShortPending(!shortPending)
      } else {
        notification({
          title: "Error Getting List of Employees",
          message: data.message,
          type: "danger",
        });
      }
    } else {
      notification({
        title: "Network",
        message: "Something has gone wrong. Please, try again.",
        type: "danger",
      });
    }
  };

  const getApprovedMemo = async (pageNumber, pageSize, queryFilter) => {
    let status = 3;
    // let payload = {
    //   pageNumber, pageSize, queryFilter
    // }

    //  const status =
    //     [statuz]

    // const res = await get({ endpoint: `/api/Memo/memos`,pQuery:{status,pageNumber,pageSize},  auth: true, });
    const res = await newget({
      endpoint: `/api/Query/replied-queries`,
      // body: payload,
      auth: true,
    });
    console.log(res);
    // console.log(res.headers['x-pagination']);
    // setapprovedMemoLength(JSON.parse(res.headers['x-pagination']).TotalCount)
    // setapprovedMemoLength(res.headers['x-pagination'])
    // const res = await get({ endpoint: `/api/Memo/memos`,pQuery:{status:status}, auth: true });
    console.log(res);

    if (res && res.status === 200) {
      const { data } = res;
      if (data.code === 1) {
        console.log(data.data);
        setApprovedMemos(data.data);
        isApproved && setPendingApprovals(data.data);
        setIsSpin(false);

        // setMapPending(data.data)
        //   reducedPending(data.data)
        //   setShortPending(!shortPending)
      } else {
        notification({
          title: "Error Getting List of Employees",
          message: data.message,
          type: "danger",
        });
      }
    } else {
      notification({
        title: "Network",
        message: "Something has gone wrong. Please, try again.",
        type: "danger",
      });
    }
  };
  //   const handleOk = () => {
  //     // setIsModalVisible(false);
  //   };

  // useEffect(() => {
  //   // alert(queryFilter)
  //   //   console.log(prop.modulename)
  //   if (isPending) {
  //     getPendingMemo(1, 10, queryFilter);
  //   } else if (isApproved) {
  //     getApprovedMemo(1, 10, queryFilter);
  //   } else {
  //     // console.log(queryFilter)
  //     getDeclinedMemo(1, 10, queryFilter);
  //   }
  // }, [queryFilter]);

  const pagOnChange = (page, pageSize) => {
    setholdPageNumber(page);
    setIsSpin(true);

    if (isPending) {
      getPendingMemo(page, pageSize);
      setIsApproved(false);
      setIsDeclined(false);
    } else if (isApproved) {
      getApprovedMemo(page, pageSize);
    } else {
      getDeclinedMemo(page, pageSize);
    }
  };

  const columns = [
    {
      title: "Subject",
      dataIndex: "subject",
      key: "subject",
      width: "25%",
    },
    // {
    //   title: 'Category',
    //   dataIndex: 'category',
    //   key: 'category',
    // },
    // {
    //   title: 'Details',
    //   key: 'details',
    //   dataIndex: 'details',
    //   width:"40%"
    // },
    {
      title: "Initiated By",
      key: "initiatedBy",
      dataIndex: "initiatedBy",
    },
    {
      title: "Date created",
      key: "dateCreated",
      dataIndex: "dateCreated",
    },
    {
      title: "Status",
      // key: 'status',
      dataIndex: "status",
      render: (text, record) => {
        if (record.status == "Pending")
          return (
            <div className="flex-align">
              <BsFillCircleFill
                color="#FDCC29"
                style={{ marginRight: "5px" }}
              />
              <p className="no-margin">Pending</p>
            </div>
          );
        // else if (
        //   record.status == "approved" ||
        //   record.status == "Approved" ||
        //   record.status == 3
        // )
        // return (
        //   <div className="flex-align" style={{ marginLeft: "5px" }}>
        //     <BsFillCircleFill color="green" style={{ marginRight: "5px" }} />
        //     <p className="no-margin">Approved</p>
        //   </div>
        // );
        // else if (record.status == 4)
        //   return (
        //     <div className="flex-align" style={{ marginLeft: "5px" }}>
        //       <BsFillCircleFill color="red" style={{ marginRight: "5px" }} />
        //       <p className="no-margin">Declined</p>
        //     </div>
        //   );
        else if (record.status == "Replied")
          return (
            <div className="flex-align" style={{ marginLeft: "5px" }}>
              <BsFillCircleFill color="blue" style={{ marginRight: "5px" }} />
              <p className="no-margin">Replied</p>
            </div>
          );
        else return <p></p>;
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (text, record) => {
        return (
          <Button
            onClick={() => {
              showPreview();
              setContent(record.modal);
              setReplies(record.reply);
              console.log(record);
            }}
            style={{
              backgroundColor: "#1C811C",
              border: "none",
              color: "white",
            }}
          >
            View Details
          </Button>
        );
      },
    },
  ];

  // const {handleShow6} = useContext(ModalContext)
  const [loading, setLoading] = useState(false);
  const [preview, setPreview] = useState(false);

  const handleCancel = () => {
    showPreview(!preview);
    // setPropz('')
    // setRefresh(refresh+1)
  };
  const showPreview = () => {
    setPreview(!preview);
    // setPropz('preview')
  };

  const mapper = () => {
    console.log(pendingApprovals?.map((e) => e?.queryRequest));
    let data = pendingApprovals?.map((res) => ({
      key: res?.queryRequest?.id,
      initiatedBy: res?.enquiry?.assigner,
      subject: res?.queryRequest?.subject || res.queryRequest?.title,
      approvers: res?.queryRequest?.memoRequestApprovers,
      details: res?.queryRequest?.details || res.queryRequest?.description,
      documents: res?.queryRequest?.memoRequestDocuments,
      dateCreated:
        res?.queryRequest?.createdAt &&
        moment(res?.queryRequest?.createdAt).format("LL"),
      status:
        res?.enquiry?.status == "Issued" && isPending ? "Pending" : "Replied",
      modal: res?.queryRequest,
      reply: res?.replies,
    }));
    setData1(data);
  };

  return (
    <div className="w-100">
      <h3 className="bold-text">All Query</h3>

      <div className="flex-between m-b-10 ">
        <div
          onClick={() => {
            setToAssigned(false);
            setPendingApprovals(pendingMemos);

            setIsDeclined(false);
            setIsApproved(false);
            setqueryFilter(null);
            setIsPending(true);
            varyPaginationLengthPending();
          }}
          className="w-100 pointer m-r-10"
          style={{ borderBottom: "3px solid green" }}
        >
          <div className="mFiles-hover">
            <div
              className="flex-between"
              style={{ padding: "15px", marginBottom: "3px" }}
            >
              <p>Pending Query</p>
              {prop.image}
            </div>
            <h2 style={{ padding: "0 15px" }}>
              <b>{pendingMemos.length}</b>
            </h2>
          </div>
        </div>

        <div
          onClick={() => {
            setToAssigned(false);
            varyPaginationLengthApproved();
            setIsDeclined(false);
            setIsPending(false);
            setqueryFilter(null);
            setPendingApprovals(approvedMemos);
            setIsApproved(true);
          }}
          className="w-100 pointer m-r-10"
          style={{ borderBottom: "3px solid green" }}
        >
          <div className="mFiles-hover">
            <div
              className="flex-between"
              style={{ padding: "15px", marginBottom: "3px" }}
            >
              <p>Replied Query</p>
              {prop.image}
            </div>
            <h2 style={{ padding: "0 15px" }}>
              <b>{approvedMemos.length}</b>
            </h2>
          </div>
        </div>

        {/* <div
          onClick={() => {
            setToAssigned(false);
            setIsApproved(false);
            setqueryFilter(null);
            setIsPending(false);
            varyPaginationLengthDeclined();
            setPendingApprovals(declinedMemos);
            setIsDeclined(true);
          }}
          className="w-100 pointer"
          style={{ borderBottom: "3px solid green" }}
        >
          <div className="mFiles-hover">
            <div
              className="flex-between"
              style={{ padding: "15px", marginBottom: "3px" }}
            >
              <p>Declined Memos</p>
              {prop.image}
            </div>
            <h2 style={{ padding: "0 15px" }}>
              <b>{declinedMemosLength}</b>
            </h2>
          </div>
        </div> */}

        {/* <div className='border' onClick={()=>setPendingApprovals(savedMemos)}>Saved</div> */}
      </div>

      {/* <div className='flex-align m-b-5 flex-between' style={{width:"23%", marginLeft:"auto",}}>
 <Button className='' style={{backgroundColor:'#1C811C', color:"white", borderRadius:"10px"}} onClick={()=>setPendingApprovals(pendingMemos)}>Pending Memos</Button>

   <Button className='' style={{backgroundColor:'#1C811C', color:"white", borderRadius:"10px"}} onClick={()=>setPendingApprovals(actionedMemos)}>Treated Memos</Button>
 </div> */}

      {toAssigned ? (
        <Table bordered columns={columns} dataSource={data2} />
      ) : (
        <div style={{ textAlign: "center" }}>
          <Input
            value={queryFilter}
            style={{ width: "50%", marginBottom: "10px", marginTop: "10px" }}
            placeholder="Search for query"
            onChange={(e) => searchQuery(e.target.value)}
          />

          {!isSpin ? (
            <Table
              pagination={false}
              bordered
              columns={columns}
              dataSource={data1}
            />
          ) : (
            <Spin
              size="large"
              spinning={isSpin}
              style={{ textAlign: "center", fontSize: "130px" }}
            />
          )}

          <div className="m-r-5">
            <Pagination
              style={{
                marginLeft: "auto",
                marginTop: "5px",
                textAlign: "right",
              }}
              onChange={pagOnChange}
              total={paginationLength}
            />
          </div>
        </div>
      )}

      <Modal
        width="70%"
        // confirmLoading
        // okButtonProps={onclick=()=>{alert("How far")}}
        footer={false}
        // okText={<p style={{ maxWidth: "100px" }}>Boss</p>}
        closeIcon={
          <CloseOutlined
            style={{
              fontSize: "25px",
              backgroundColor: "red",
              color: "white",
              padding: "10px",
              marginLeft: "60px",
            }}
          />
        }
        visible={preview}
        onCancel={handleCancel}
      >
        <div style={{ marginTop: "-23px" }}>
          {/* { toAssigned?
                     <AssignedMemoz
                     handleCancel2={handleCancel2} 
                     replies = {content}
                     />
                     : */}
          <ApproveDeclineMemos
            handleCancel2={handleCancel2}
            contents={content}
            replies={replies}
            isPending={isPending}
            // previewValues = {previewValues}
            // payload = {payload}
            // signature ={signature}
            // save = {propz}
            // treated = {propzApproved}

            p="Edit"
            // modalContent={modalContent}
          />
        </div>
      </Modal>
    </div>
  );
};
export default AllMemos;

// const cards = houseProperties.map(item => <div className= "houseMath4" style={{border:""}}><Link to = "/page3">
// <Picture_card card = {item} height = "200px"/>
// </Link> </div> )
