import React, { useContext, useEffect, useState } from "react";
import { Select, Form } from "antd";

import { PaperClipOutlined, DeleteTwoTone } from "@ant-design/icons";
// import ItemRequest from './ItemRequest';
import Cookies from "js-cookie";
import axios from "axios";
import { SwapTableContext } from "../Contexts/SwapTableContext";

// import { SwapTableContext } from './Contexts/SwapTableContext'

const { Option } = Select;

function SearchDocument(prop) {
  const [title, setTitle] = useState([]);
  const [mFilesVal, setMfilesVal] = useState({ fileUrl: "", name: "" });
  const [mapVal, setMapVal] = useState([]);
  let [count, setCount] = useState(0);

  const [form] = Form.useForm();

  const {
    handleImage,
    imageHolder,
    mFilesArray,
    handleMfilesArray,
    holdMfilesArray,
  } = useContext(SwapTableContext);

  console.log(mFilesVal);
  useEffect(() => {
    holdMfilesArray([]);
    onReset();
  }, []);

  const userId = Cookies.get("userId");

  const onReset = () => {
    form.resetFields();
  };

  const change = (e) => {
    console.log(e);
    setTitle(e);
    onReset();
  };
  useEffect(() => {
    console.log(userId);
    console.log(title);
    searchDocs(userId, title);
  }, [title]);

  const searchDocs = (userId, title) => {
    const options = {
      url: `https://edogoverp.com/services/api/dashboard/searchdocument?user_id=${parseInt(
        userId
      )}&DocTitle=${title}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        crossorigin: true,
        "Access-Control-Allow-Methods": "GET",
        "Access-Control-Allow-Origin": "*",
      },
    };
    // console.log(options);
    return axios(options).then((res) => {
      console.log(res);
      //  let b=res.data
      // let a =[]
      const a =
        Array.isArray(res?.data) &&
        res?.data?.map((tem) => (
          <Option value={tem.title} key={tem.fileurl}>
            {tem.title}
          </Option>
        ));
      setMapVal(a);
      console.log(a);

      //
      // (err) => console.log(err.response)
    });
  };

  const del = (id) => {
    console.log(id);
    console.log(mFilesArray);
    // dataSource.filter((item) => item.key !== key)
    const pp = mFilesArray.filter((x) => x.key !== id);
    holdMfilesArray(pp);
    imageHolder.name = "";
    imageHolder.fileUrl = "";
    handleImage("", "");
  };

  // const del = (id)=>{
  //   console.log(id)
  //   console.log(mFilesArray)
  //   // dataSource.filter((item) => item.key !== key)
  //   let pp = mFilesArray.filter((x)=>(
  // x.id !== id
  //   ))
  //   holdMfilesArray(pp)
  //     mFilesVal.name="";
  //     mFilesVal.fileUrl="";
  //     handleImage("","")

  // }

  return (
    <div style={{ width: "100%" }}>
      <Form form={form}>
        <Form.Item>
          <Select
            showSearch
            style={{ width: "100%" }}
            // placeholder = 'hekko'
            placeholder="Select from M-File"
            optionFilterProp="children"
            onSearch={(value) => {
              change(value);
            }}
            // value={imageHolder.name}
            // onFocus={onFocus}
            // onBlur={onBlur}
            onChange={(text, index) => {
              handleImage(index.value, index.key);

              handleMfilesArray({
                key: count++,
                id: 0,
                name: index.value,
                path: index.key,
              });
              // let a = prop?.handleDocAdd({
              //   key:count++,
              //   name : index.value,
              //   path : index.key
              // })
              setCount(count);
              setMfilesVal({
                name: index.value,
                fileUrl: index.key,
              });
              handleImage("", "");
            }}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {mapVal}
          </Select>
        </Form.Item>
      </Form>

      {mFilesArray.map((x) => (
        <div
          style={{
            padding: "5px",
            border: "1px solid ash",
            marginBottom: "2px",
          }}
          // style={{display:(mFilesVal.name=="" ||mFilesVal.name==null||mFilesVal.name==undefined ? "none":"flex"), marginTop:"5px", border:"2px solid red"}}
          className="flex-between mFiles-hover flex-align"
        >
          <div className="flex-align">
            <PaperClipOutlined />
            <a
              href={x.path}
              target="_blank"
              download={x?.name}
              style={{ marginLeft: "3px", marginBottom: "0px" }}
            >
              {x.name}
            </a>
          </div>

          <div
            className="pointer"
            onClick={() => {
              del(x.key);
            }}
          >
            <DeleteTwoTone style={{ fontSize: "16px" }} />
          </div>
        </div>
      ))}

      {
        console.log("Hello:", mFilesArray)
        //  console.log()
      }
    </div>
  );
}

export default SearchDocument;
